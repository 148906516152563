.grid1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;
}

.grid1__item {
    background-color: #1c2237;
    padding: 20px;
    text-align: center;
    color: #fff;
}

.grid1__icon{
    font-size: xx-large;
}

@media screen and (max-width: 960px) {
    .grid1 {
        grid-template-columns: 1fr;
    }
}