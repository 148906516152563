.top-img {
    background-size: cover;
    background-position: 50%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--mainBlue);
    padding: 0 20px;
}

.about__heading {
    color: #1c2237;
    margin-bottom: 24px;
}

.about__heading h3 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 600;
}

.about__heading p {
    font-size: large;
    line-height: 1.1;
}