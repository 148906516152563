.l-page {
    background: linear-gradient(90deg, rgb(110, 94, 254) 0%, rgba(73, 53, 252, 1) 100%);
    height: 100vh;
}

.heading {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
}

.l-page p {
    font-size: large;
    color: #fff;
}

.ld-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: 50%;
}

@media screen and (max-width: 960px) {
    .ld-image {
        width: 90%;
    }

    .l-page {
        height: 1200px;
    }
}