.event-top-section {
    color: #000;
    padding: 10px 0;
}

.lightBg {
    background-color:#fff;
}

.event__section {
    padding: 50px 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
}

.event__container-card {
    background: #fafafa;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 380px;
    height: 380px;
    text-decoration: none;
    margin-bottom: 20px;
    border-radius: 4px;
}
/*
.event__container-card:nth-child(2) {
    transform: scale(1.05);
    background: #242424;
}
*/

.event__container-card:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
}
/*
.event__container-card:nth-child(2) {
    margin: 0 24px;
}
*/
.event__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.event__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
}

.event__heading {
    color: #1c2237;
    margin-bottom: 24px;
}

.event__container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 280px;
    padding: 24px;
    align-items: center;
    color: #fff;
    text-decoration: none;
}

.event__container-cardInfo h3 {
    margin-bottom: 5px;
    font-size: 24px;
}

.event__container-cardInfo h4 {
    font-size: 40px;
}

.event__container-cardInfo p {
    font-size: 14px;
    margin-bottom: 10px;
    color: black;
}

.event__container-features {
    margin: 16px 0 32px;
    list-style: none;
}

.event__container-features li {
    margin-bottom: 10px;
}

.event__container-features {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    margin: 24px 0;
}

.home__hero-img {
    margin: 10px 0;
    width: 18rem;
}

@media screen and (max-width: 960px) {
    .event__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .event__container-card {
        width: 90%;
    }

    .event__wrapper {
        margin: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    /*
    .event__container-card:nth-child(2) {
        transform: scale(1);
        background: #242424;
        margin: 24px;
    }
    */
    .event__container-card:hover {
        transform: none;
    }
}